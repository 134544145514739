.bodyXs {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}
//styleName: Heading - Small;
.headingSmall {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
//styleName: Body - Small;
.bodySmall {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
}
.bodySmall2 {
  //styleName: Body - Small :emphasized;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
}
.bodyMedium {
  //styleName: Body - Medium;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}
.bodyMedium2 {
  //styleName: Body - Medium :emphasized;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}
.bodyLarge {
  //styleName: Body - Large :emphasized;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.bodyLarge2 {
  //styleName: Body - Large2;
  font-family: IBM Plex Sans;
  letter-spacing: 0em;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
