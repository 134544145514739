@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';
@import 'styles/query';

.trips {
  display: flex;
  flex-direction: column;
  background-color: $clarity-white;
  @include lgMax {
    grid-area: tripsList;
  }
  @include lgMin {
    flex: 1;
  }
}

.noComponentPageTrips {
  margin-right: 1.25rem;
  padding: 1.25rem;

  @include lgMax {
    margin-right: 0;
  }
}

.tab {
  display: flex;
  gap: 8px;
}

.fw500 {
  font-weight: 500;
}

.header {
  @include flex(row, nowrap, flex-start, center);
  @include text(16px, 500);
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  .title {
    font-weight: 500;
  }
}

.tripsContainer {
  margin-top: 10px;
  border: 2px solid $grey-1;
}

.showCanceled {
  display: flex;
  align-items: center;
  gap: 8px;
}

.loadMore {
  margin-top: 10px;
  justify-content: center;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
}

.emptyContainer {
  margin-top: 24px;
  display: flex;
  gap: 16px;
  justify-content: center;

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .main {
      font-size: 16px;
      color: $grey-6;
    }

    .sub {
      font-size: 14px;
      color: $grey-5;
    }
  }
}
