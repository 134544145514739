@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';

.table {
  width: 100%;
  table-layout: fixed;
}

.head {
  height: 30px;
  font-size: 12px;
  font-weight: 400;
  color: $charcoal-grey;

  & tr {
    text-align: left;
  }
}

.body {
  background-color: $clarity-white;
  border: #e9e9e9 1px solid;
}

.row {
  &:hover {
    background-color: $grey-1;
  }

  height: 44px;
  cursor: pointer;

  & td {
    min-width: 30px;
    border-bottom: #e9e9e9 1px solid;
  }

  & td:last-child {
    cursor: pointer;
  }

  & td:first-child {
    padding-left: 12px;
    padding-right: 8px;
  }
}

.active {
  & td {
    border-bottom: none !important;
  }
}

.expand {
  display: flex;
  justify-content: flex-end;
  margin-right: 12px;
}

.expanded {
  height: 160px;
  border-bottom: #e9e9e9 1px solid;
  padding: 14px;
  display: flex;
}

.expander {
  @include flex;
  height: 30px;
}

.details {
  width: 430px;
}

.detailsGroup {
  margin-bottom: 18px;
}

.detailsTitle {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 5px;
}

.headerText {
  padding-right: 5px;
  min-width: max-content;
}

.calendarContainer {
  overflow-y: scroll;
  padding-right: 15px;
  line-height: 21px;
}

.buttonContainer {
  width: 200px;
}

.inlineContainer {
  @include flex(row, nowrap, flex-end, center);

  & > * {
    margin-right: 6px;
  }
}

.copyBtn {
  cursor: pointer;
}

.nameField {
  display: flex;
  align-items: center;
}
