// brand colors
$fcm-green: #00de00;
$deep-green: #17be17;
$green-highlight: #e9f9e3;
$clarity-white: #ffffff;
$white-a70: rgba(255, 255, 255, 0.7);
$white-a30: rgba(255, 255, 255, 0.3);
$charcoal-grey: #505050;
$midnight-black: #000000;
$black-a70: rgba(0, 0, 0, 0.7);
$black-a30: rgba(0, 0, 0, 0.3);
$grey-1: #f5f5f5;
$grey-2: #e5e5e5;
$grey-3: #d9d9d9;
$grey-4: #c6c6c6;
$grey-5: #aaaaaa;
$grey-6: #818181;
// ui colors
$ui-blue: #1f8edf;
$ui-blue-darken: #0e7ac8;
$ui-blue-lighten: #e9f4fc;
$ui-orange: #f29036;
$ui-orange-darken: #e57610;
$ui-orange-lighten: #fef4eb;
$ui-green: #41bf83;
$ui-green-darken: #339968;
$ui-green-lighten: #ecf9f3;
$ui-red: #e73434;
$ui-red-darken: #cd1818;
$ui-red-lighten: #fdebeb;
