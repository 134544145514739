@import 'styles/palette';

h3 {
  margin: 0;
}

.formInputLabel {
  font-family: 'Roboto';
  font-weight: 400;
}

.notesContainerSpacing {
  padding: 16px;
  margin-top: 16px;
}

.notesContainer {
  background: #fff;
  flex: 1;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $midnight-black;
  }

  .activeNoteBtn,
  .noteBtn:active {
    color: $fcm-green !important;
    svg path {
      fill: $fcm-green !important;
    }
  }
  .noteBtn {
    display: flex;
    gap: 6px;
    cursor: pointer;

    // font-family: 'Roboto';
  }
}

.priorityContainer {
  background: $grey-1;
  display: flex;
  justify-content: stretch;
  align-items: center;
  margin: 8px 0 12px;
  // height: 36px;
}

.notSelectedPriority {
  p {
    color: $grey-6 !important;
  }
}

.selectedPriority {
  border-radius: 2px;
  margin: 3px 0;
  background: $clarity-white;
}

.bold {
  font-weight: 500;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
}

.mb8 {
  margin-bottom: 8px;
}

.emptyNotes {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 24px 0px;

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .main {
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $grey-6;
    }

    .sub {
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $grey-5;
    }
  }
}

.buttons {
  display: flex;
  gap: 4px;
}

.mt4 {
  margin-top: 4px;
}

.noteInputContainer {
  display: flex;
  flex-direction: column;
  outline: 2px solid $deep-green;
  padding: 12px;

  .title {
    // font-family: 'Roboto';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $midnight-black;
    margin-bottom: 8px;
  }

  .textarea {
    box-sizing: border-box;
    padding: 8px;
    gap: 8px;
    height: 84px;
    background: $clarity-white;
    border: 1px solid $grey-5;
    margin-bottom: 8px;
    resize: none;
  }

  .errorTextarea {
    border: 1px solid $ui-red-darken !important;
    outline-color: $ui-red-darken;
    outline-width: 1px;
  }
  .errorInfo {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 8px;
  }

  .submitBtn {
    padding: 8px 12px;
    gap: 10px;
    width: 83px;
    height: 36px;
    background: $charcoal-grey;
    //background: #C6C6C6;
    font-size: 14px;
  }

  .cancelBtn {
    width: 83px;
    height: 36px;
    // font-family: 'Roboto'; // TODO: do we need Roboto here or is it a design mistake?
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $midnight-black;
    border: none;
    background: transparent;
  }
}

.deleteBtn {
  gap: 4px;
}

.loadMoreBtn {
  justify-content: center;
  width: 100%;
}

.halfbold {
  font-weight: 500;
}

.flex {
  display: flex;
}

.notesListContainer {
  margin-top: 8px;
}

.notesList {
  border: 2px solid $grey-2;
  margin: 8px 0px;
  gap: 8px;
}

.actionsContainer {
  display: flex;
  gap: 8px;
  margin-left: auto;
}
.withUpdated {
  align-items: center;
}

.popover {
  display: flex;
  flex-direction: column;
  padding: 4px 0;
}

.popoverItem {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 8px;
  gap: 8px;
}

.note {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 4px;
  word-break: break-all;

  .text {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $midnight-black;
  }

  .subRow {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .author {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $charcoal-grey;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .time {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $charcoal-grey;
  }

  .editActionsBtn {
    margin-left: auto;
    cursor: pointer;
    &:hover svg path {
      fill: $deep-green;
    }
    &:active svg path {
      fill: $fcm-green;
    }
  }
}

.noteContainer:not(:last-child) {
  border-bottom: 1px solid $grey-2;
}
