@import 'styles/palette';

.resultsContainer {
  margin-top: 16px;
  width: 100%;
}

.chevron {
  align-self: baseline;
  margin-top: 6px;
  flex-shrink: 0;
}

.searchRow,
.header {
  display: flex;
  align-items: center;
  width: 100%;
}

.header {
  padding: 0 12px 12px 12px;
}

.columnHeader:first-child {
  position: relative;
  right: 12px;
}

.searchRow {
  padding: 12px;
  gap: 8px;
  cursor: pointer;
  background-color: white;

  &:hover {
    background-color: $grey-1;
  }
}

.searchRowsContainer {
  border: 2px solid $grey-2;
}

.searchRow:not(:last-child) {
  border-bottom: 1px solid $grey-2;
}

.column {
  flex: 3;
  max-width: 25%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:last-child {
    flex: 0;
    min-width: 20px;
  }
}
