@import 'styles/palette';
.button {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
.primary {
  background: $midnight-black;
  color: $clarity-white;
  border: none;
  &:hover {
    background: $deep-green;
  }
  &:active {
    background: $fcm-green;
  }
  &:disabled {
    background: $grey-4;
    color: $white-a70;
    cursor: not-allowed;
  }
}
.padding {
  padding: 8px 12px;
}
.secondary {
  color: $midnight-black;
  border: 1px solid $midnight-black;
  background: transparent;
  &:hover {
    border: 1px solid $deep-green;
    color: $deep-green;
  }
  &:active {
    border: 1px solid $fcm-green;
    color: $fcm-green;
  }
  &:disabled {
    border: 1px solid $grey-4;
    color: $grey-4;
    cursor: not-allowed;
  }
}
.text {
  border: none;
  background: transparent;
  &:hover:not([disabled]) {
    color: $deep-green;
    p {
      color: $deep-green !important;
    }
    svg {
      color: $deep-green !important;
    }
  }
  &:active {
    color: $fcm-green;
    p {
      color: $fcm-green !important;
    }
    svg {
      color: $fcm-green !important;
    }
  }
  &:disabled {
    color: $grey-4;
    cursor: not-allowed;
  }
}
