@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';

.searchContainer {
  margin-top: 20px;
  width: 100%;
  height: 40px;
}

.search {
  width: 100%;
  height: 36px;
  background-color: $clarity-white;
  border: 1px solid $grey-5;
  color: $grey-5;
  @include flex(row, nowrap, flex-start);

  &:focus-within {
    border: 1px solid $deep-green;

    svg path {
      fill: $deep-green;
    }
  }
}

.searchField {
  @include text(14px, 400, 20px);
  color: $midnight-black;
  width: 100%;

  &::placeholder {
    color: $grey-4;
  }
}
