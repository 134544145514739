@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';

.fitContent {
  width: fit-content;
}

.bar {
  grid-area: bar;
  padding-right: 16px;
  min-width: 240px;

  & > div {
    margin-bottom: 10px;
  }

  & .field {
    @include text(12px, 400, 16px);
    color: $charcoal-grey;
    text-align: left;
  }
}
.value {
  @include flex(row, nowrap, flex-start, center);
  @include text(14px, 500, 20px);
  color: $midnight-black;
  text-align: left;
  word-break: break-word;

  & > a {
    color: black;
    text-decoration: none;
  }
}
.hr {
  margin-top: 16px;
  height: 8px;
  border-top: 1px solid $grey-3;
}

.additionalInfoItem {
  @include flex(row, nowrap, flex-start, center);
  font-weight: 500;
  padding: 4px 8px;
  margin: 4px 0;
  background-color: $clarity-white;
  min-height: 28px;

  & > svg {
    margin-left: auto;
  }
}

.copy {
  cursor: pointer;
  margin-left: 6px;
  width: 13px;
  height: 13px;
}

.gap8 {
  gap: 8px;
}

.addInfoList {
  width: fit-content;
  padding-right: 5px;

  .addInfoItem {
    margin-bottom: 8px;
  }

  .addInfoLabel {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $charcoal-grey;
  }

  .addInfoValue {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $midnight-black;
  }
}

.emailLink {
  text-decoration: underline !important;
}

.arrangerName,
.approverName {
  word-break: normal;
  overflow-wrap: anywhere;
}
