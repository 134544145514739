@import 'styles/palette';

.page {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: $clarity-white;
  position: relative;
  display: flex;
  justify-content: center;
}

.searchItemBlock {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.backgroundText {
  margin-top: 40px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: $grey-6;

  & > div {
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.inputContainer {
  position: relative;
  width: 100%;
  height: 36px;
  border: 1px solid $grey-5;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  .inputIcon svg path {
    fill: $grey-5;
  }

  &:focus-within {
    border: 1px solid $deep-green;

    .inputIcon svg path {
      fill: $deep-green;
    }
  }
}

.inputIcon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
  margin-right: 10px;
}

.input {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $midnight-black;

  &::placeholder {
    color: $grey-4;
  }
}

.resultsContainer {
  position: absolute;
  top: 36px;
  left: 0;
  width: 100%;
  background-color: $clarity-white;
  box-shadow: 0 9px 19px -7px rgba(0, 0, 0, 0.67);
  // overflow: hidden;
}

.searchRow,
.error {
  display: flex;
  align-items: center;
  padding: 0 8px;
  width: 100%;
  height: 36px;
}

.searchRow {
  cursor: pointer;
  gap: 8px;
}

.error {
  color: $charcoal-grey;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.email {
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
}

.company {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header {
  font-weight: 500;
  cursor: default;
  &:hover {
    background-color: transparent;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.3em solid $grey-3;
  border-right: 0.3em solid $grey-3;
  border-bottom: 0.3em solid $grey-3;
  border-left: 0.3em solid $deep-green;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
