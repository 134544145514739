@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';

.container {
  display: flex;
  flex-direction: column;
  background-color: $clarity-white;
}

.pointer {
  cursor: pointer;
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .headingContent {
    display: inline-block;
    padding: 0px 8px;
    position: relative;
    background: $clarity-white;
    z-index: 1;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 0;

    content: '';
    width: 100%;
    border-bottom: 1px solid $grey-3;
  }
}

.header {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
}

.tabsContainer {
  padding-bottom: 30px;
}

.inlineIcon {
  display: flex;
  align-items: center;
}

.preferencesContainer {
  margin-top: 8px;

  & * {
    color: $midnight-black;
  }
}

.membershipContainer {
  & * {
    color: $midnight-black;
  }
}
.mt4 {
  margin-top: 4px;
}
.emptyContainer {
  margin-top: 24px;
  display: flex;
  gap: 16px;
  justify-content: center;

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .main {
      font-size: 16px;
      color: $grey-6;
    }

    .sub {
      font-size: 14px;
      color: $grey-5;
    }
  }
}
