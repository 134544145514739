@import 'styles/palette';

.loader {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  border: 8px solid $grey-3;
  border-top-color: $deep-green;
  box-sizing: border-box;
  background: transparent;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(360deg);
  }
}
