@import 'styles/palette';

.checkbox input[type='checkbox'] {
  display: none;
}
.checkbox {
  height: 20px;
  width: 20px;
  position: relative;
}
.checkbox input[type='checkbox'] + label {
  cursor: pointer;
}

.checkbox input[type='checkbox'] + label:before {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid $grey-4;
  background: $clarity-white;
  content: '';
}

.checkbox:hover + label:before {
  border: 1px solid $grey-5;
}

.checkbox input:checked + label:before {
  background: $deep-green;
  border: 1px solid $deep-green;
}
.checkbox:hover input:checked + label:before {
  background: $fcm-green;
  border: 1px solid $fcm-green;
}

.checkbox input:checked:disabled + label:before {
  background: $grey-5;
  border: 1px solid $grey-5;
}
.checkbox input:disabled + label:before {
  background: $grey-1;
  border: 1px solid $grey-3;
}

.checkbox input:checked + label:after {
  display: inline-block;
  position: absolute;
  left: 5px;
  top: 5px;
  width: 9px;
  height: 6.5px;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  border-radius: 1px;
  content: '';
  transform: rotate(-45deg);
}
