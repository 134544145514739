@import 'styles/layout';
@import 'styles/typography';
@import 'styles/query';
@import 'styles/palette';

.container {
  @include flex(row, nowrap, flex-start, stretch);
  margin-top: 20px;
  width: 100%;
  flex-grow: 1;

  @include lgMax {
    display: grid;
    justify-content: normal;
    grid-template-columns: 250px auto;
    grid-template-areas:
      'bar tripsList'
      'bar trendsNotes';
  }
}

.preferencesContainer {
  grid-area: trendsNotes;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  overflow: hidden;

  @include lgMax {
    margin-top: 16px;
  }
}
