@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';
@import 'styles/query';

.name {
  color: $charcoal-grey;
  @include text(16px, 500, 24px);
}

.lastCrumbName {
  color: $midnight-black;
}
.crumbOfMany {
  width: 45% !important;
  flex-grow: 1;
}

.crumb {
  position: relative;
  width: 100%;

  &:not(:last-child) {
    cursor: pointer;
  }
}
.name {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.position {
  @include text(12px, 400, 16px);
  color: $charcoal-grey;
}

.lastCrumbPosition {
  color: $midnight-black;
}
.chevron {
  align-self: baseline;
  margin-top: 6px;
  flex-shrink: 0;
}
