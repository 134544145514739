@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';

.container {
  @include flex(column, nowrap, center, center);
}

.title {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: $midnight-black;
  margin-top: 20px;

  & > img {
    margin-bottom: 20px;
  }
}

.successImg {
  width: 73px;
  height: 73px;
}

.score {
  cursor: pointer;
  margin-top: 32px;
  height: 40px;
  @include flex(row, nowrap, space-between, center);

  & > div {
    width: 80px;
    @include flex(column, nowrap, center, center);
  }
}

.text {
  margin-top: 32px;
  width: 584px;
  height: 236px;
  background: $clarity-white;
  border: 1px solid $grey-5;
  box-sizing: border-box;

  & textarea {
    width: 100%;
    height: 100%;
    padding: 20px;
    resize: none;
    appearance: none;
    border: none;

    &:focus {
      outline: none;
    }
  }
}

.button {
  margin-top: 20px;
  user-select: none;
}

.active {
  filter: brightness(0.6);
}

.mt16 {
  margin-top: 16px;
}

.hint {
  margin-top: 20px;
  color: red;
}
