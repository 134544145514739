@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';
@import 'styles/query';

.line {
  background-color: $deep-green;
  width: 100%;
  height: 4px;
  flex-shrink: 0;
}

.headerContainer {
  background-color: $green-highlight;
  width: 100%;
  height: 32px;
  @include flex(row, nowrap, flex-start, center);
  @include text(12px, 400, 16px);
}

.multipleProfiles {
  display: flex;
  flex-direction: column;
}

.header {
  @include flex(row, nowrap, flex-start, center);
  overflow: hidden;
  gap: 30px;
}

.contactItem {
  @include flex(row, nowrap, flex-start, center);

  & > span {
    margin-left: 4px;
  }
}
.phoneEmail {
  min-width: fit-content;
}
.companyName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menuContainer {
  background-color: $clarity-white;
  width: 100%;
  border-bottom: 1px solid $grey-2;

  & .menu {
    @include flex(row, nowrap, flex-start, center);
    min-height: 100%;
  }

  & .searchContainer {
    @include flex(row, nowrap, flex-start, center);

    margin-left: auto;
    height: 100%;
    width: 142px;

    flex-shrink: 0;
  }

  .name {
    color: $charcoal-grey;
    @include text(16px, 500, 24px);
  }

  .lastCrumbName {
    color: $midnight-black;
  }

  .position {
    @include text(12px, 400, 16px);
    color: $charcoal-grey;
  }

  .lastCrumbPosition {
    color: $midnight-black;
  }
}

.searchExpanded {
  padding-left: 12px;
  width: 240px;
  height: 36px;
  background-color: $grey-1;
  color: $grey-5;
  @include flex(row, nowrap, flex-start, center);

  & > * {
    margin-right: 12px;
  }

  & .expand {
    @include flex(row, nowrap, flex-start, center);
    margin-left: auto;

    & > * {
      margin-left: 16px;
    }
  }
}

.search {
  &:hover {
    svg path {
      fill: $deep-green !important;
    }

    button {
      background: $deep-green !important;
    }
  }

  height: 36px;
  color: $grey-5;
  @include flex(row, nowrap, flex-start, center);
  cursor: pointer;
  user-select: none;

  & > * {
    margin-right: 4px;
  }

  & .expand {
    @include flex(row, nowrap, flex-start, center);
    padding: 2px 8px;
    box-sizing: content-box;
    font-size: 12px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    margin-left: 4px;
  }

  & .active {
    @include flex(row, nowrap, flex-start, center);
    background-color: $deep-green;
    color: $clarity-white;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    padding: 2px 8px;
    box-sizing: content-box;
    font-size: 12px;
    margin-left: 4px;
  }
}

.activeSearch {
  cursor: default;
  user-select: none;
  pointer-events: none;
}

.backButton {
  @include flex;
  cursor: pointer;
  user-select: none;
  // line-height: 20px;
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
}

.section {
  @include flex;
  cursor: default;
  user-select: none;
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
}

.breadcrumbs {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 16px;
  max-width: 92%;

  .crumbOfMany {
    width: 45% !important;
    flex-grow: 1;
  }

  .crumb {
    position: relative;
    width: 100%;
    .name {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    &:not(:last-child) {
      cursor: pointer;
    }
  }

  .chevron {
    align-self: baseline;
    margin-top: 6px;
    flex-shrink: 0;
  }
}
.privacyShadow {
  filter: drop-shadow(0px 4px 24px rgba(99, 111, 122, 0.24));
}
