@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';

.content {
  background-color: $grey-1;
  flex-grow: 1;
}
.wrapper {
  display: flex;
  flex-direction: column;
}

.line {
  width: 100%;
  border-bottom: 1px solid $grey-2;
}

.statusContainer {
  width: 30%;
  height: 40px;
  padding-bottom: 10px;
  margin-left: auto;
  @include flex(row-reverse, nowrap, flex-start, center);

  & > * {
    margin-right: 4px;
  }
}

.npsContainer {
  color: $clarity-white;
  @include flex(row, nowrap, flex-start, center);
  margin-right: 20px;
}

.nps {
  background-color: $ui-orange;
  @include flex;
  height: 24px;
  width: 50px;
}

.number {
  background-color: $ui-orange-darken;
  @include flex;
  height: 24px;
  width: 20px;
}

.floatContainer {
  position: fixed;
  padding: 4px 12px;
  border-radius: 47px;
  right: 48px;
  bottom: 40px;
  user-select: none;
  gap: 4px;
  z-index: 2;
}
