@import 'styles/palette';

.retrySvg {
  height: 15px;
  width: 14.94px;
  margin-right: 6.52px;
  path {
    fill: $white-a70;
  }
}
