@import 'styles/palette';

.customSelectContainer {
  display: inline-block;
  min-width: 200px;
  text-align: left;
  position: relative;
  cursor: pointer;
  height: 36px;
}

.selectedText {
  background-color: $clarity-white;
  padding: 6px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid $grey-5;
  height: 100%;
  span {
    flex-grow: 2;
  }
}

.selectedText.active {
  border: 1px solid $deep-green;
}
.initialText {
  color: $grey-4;
}
.chevron {
  margin-right: 14px;
}
.activeChevron {
  path {
    fill: $deep-green;
  }
}

.selectedText.active::after {
  top: 8px;
  border-bottom: 1px solid $deep-green;
}

.selectOptions {
  margin: 0;
  padding: 0;
  text-align: left;
  background: $clarity-white;
  box-shadow: 0px 4px 12px rgba(99, 111, 122, 0.16);
  position: absolute;
  width: 100%;
}

.selectOption {
  list-style-type: none;
  padding: 8px;
  background: $clarity-white;
  cursor: pointer;
  &:hover {
    background-color: $grey-1;
    color: $midnight-black;
  }

  &:active {
    background-color: $green-highlight;
  }
}
