@import 'styles/palette';

.container {
  display: flex;
  background: $ui-orange-lighten;
  padding: 11.32px 16px 11.32px 17.35px;
  justify-content: space-between;
}

.assignBtn {
  margin-left: 8px;
}
