@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';

.dotsOpen {
  p {
    color: $fcm-green !important;
  }
  path {
    fill: $fcm-green !important;
  }
}
