@import 'styles/palette';

.popover {
  background-color: $clarity-white;
  filter: drop-shadow(0px 4px 12px rgba(99, 111, 122, 0.16));
  max-height: 500px;
  z-index: 99;
}
.popoverPadding {
  padding: 12px 16px;
}

.tooltip {
  background-color: $midnight-black;
  max-height: 500px;
  z-index: 99;
}

.tooltipPadding {
  padding: 8px 12px;
}

.tooltipArrow {
  height: 10px;
  width: 10px;
  background-color: $midnight-black;
  position: absolute;
}

.popoverArrow {
  height: 10px;
  width: 10px;
  background-color: $clarity-white;
  position: absolute;
}

.onboardingArrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $midnight-black;
  position: absolute;
}

.onboardingArrow.right {
  rotate: 270deg !important;
  left: -16px !important;
  transform: none !important;
}

.onboardingArrow.left {
  rotate: 90deg !important;
  right: -16px !important;
  transform: none !important;
}

.right {
  left: 0px;
  top: 50%;

  transform: translateY(-50%) rotate(45deg);
}

.left {
  right: -4px;
  top: 50%;

  transform: translateY(-50%) rotate(45deg);
}

.top {
  bottom: -4px;
  left: 50%;

  transform: translateX(-50%) rotate(-45deg);
}

.bottom {
  left: 50%;
  top: -4px;

  transform: translateX(-50%) rotate(45deg);
}
