@mixin lgMin() {
  @media (min-width: 1120px) {
    @content;
  }
}

@mixin lgMax() {
  @media (max-width: 1119px) {
    @content;
  }
}
