@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
}

// HACK for resizing
#root {
  position: relative;
  max-width: 100%;
}

@media (max-width: 819px) {
  #root {
    min-width: 820px;
  }

  #root:has(.noMinWidth) {
    min-width: 0px;
  }
}

// @media (max-width: 767px) {
//   #root {
//     min-width: 768px;
//   }

//   #root:has(.noMinWidth) {
//     min-width: 0px;
//   }
// }
main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 1px;
}

table,
thead,
tbody,
tfoot,
th,
td,
tr {
  font-weight: unset;
  border-spacing: unset;

  margin: 0;
  padding: 0;
  border-collapse: collapse;
  // all: unset;
}

input {
  all: unset;
}

select {
  all: unset;
}

* {
  box-sizing: border-box;
}

.wrap {
  word-break: normal;
  overflow-wrap: anywhere;
}

.hiddenWrap {
  overflow: hidden;
  overflow-wrap: anywhere;
}

.textWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.disableScroll {
  overflow-y: hidden;
}
.headingSmall {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
//styleName: Body - Small;
.bodySmall {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
}
.bodySmall2 {
  //styleName: Body - Small :emphasized;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
}
.bodyMedium {
  //styleName: Body - Medium;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}
.bodyMedium2 {
  //styleName: Body - Medium :emphasized;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}
.bodyLarge {
  //styleName: Body - Large :emphasized;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.bodyLarge2 {
  //styleName: Body - Large2;
  font-family: IBM Plex Sans;
  letter-spacing: 0em;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
