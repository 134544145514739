@import 'styles/palette';

.container {
  padding: 12px 16px;
  display: flex;
  gap: 8px;
}

.errorContainer {
  border: 1px solid $ui-red-darken;
}

.warningContainer {
  border: 1px solid $ui-orange;
}

.infoContainer {
  border: 1px solid $ui-blue-darken;
}

.successContainer {
  border: 1px solid $ui-green-darken;
}
