@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';
.lineMargins {
  margin-top: 12px;
  margin-bottom: 12px;
}
.lineContainer {
  @include flex(row, wrap, flex-start, flex-start);
  position: relative;
  width: 100%;
  z-index: 1;

  & > *:first-child {
    background-color: $clarity-white;
    word-wrap: break-word;
  }

  & > *:last-child {
    background-color: $clarity-white;
    margin-left: auto;
    padding-left: 8px;
    padding-right: 0;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: right;
  }

  &::after {
    position: absolute;
    top: 13px;
    left: 0;
    z-index: -1;
    content: '';
    width: 100%;
    border-bottom: 1px dotted $grey-3;
  }
}

.line {
  position: relative;
  z-index: 2;
  color: $charcoal-grey;
  background-color: $clarity-white;
  padding-right: 8px;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}
