@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette.scss';
@import 'styles/query.scss';

.trips {
  min-width: 320px;
  height: fit-content;
}

.container {
  display: flex;
  padding-top: 16px;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  flex: 3;
  gap: 16px;

  //  TODO check maybe we can handle it differently in trips scss
  @include lgMax {
    margin-left: 1.25rem;
  }
}

.pnrBlock {
  display: flex;
}

.infoAlertBadge {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  width: fit-content;
  background: $ui-blue-lighten;

  .infoCircle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $ui-blue-darken;
  }
}

.detailsHeader {
  display: flex;
  gap: 4px;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: $clarity-white;
  padding: 16px;

  &:last-child {
    padding-bottom: 70px;
  }
}

.jet {
  margin-top: 8px;

  path {
    fill: $deep-green;
  }
}

.fixWidth {
  overflow-wrap: break-word;

  @include lgMin {
    width: 185px;
  }
}

.optionsBlock {
  @include lgMin {
    width: 250px;
    max-width: 250px;
    flex-grow: 1;
  }

  @media (min-width: 1220px) {
    width: 320px;
    max-width: 320px;
  }
}

.segmentPrimaryInfo {
  display: grid;
  flex-grow: 1;
  gap: 4px 24px;
  grid-template-areas:
    'stops other'
    'from to';

  @include lgMin {
    display: flex;
    gap: 24px;
  }

  .from {
    grid-area: from;
  }

  .to {
    grid-area: to;
    @include lgMax {
      margin-left: auto;
    }
  }

  .stops {
    grid-area: stops;
  }

  .other {
    grid-area: other;

    @include lgMax {
      margin-left: auto;
    }
  }
}

.gdsBlock {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-areas:
    'label confirmation'
    '. status'
    '. ticket';
  padding: 0.5rem 0;

  @include lgMin {
    display: flex;
  }

  .label {
    grid-area: label;
    @include lgMax {
      height: 24px;
      margin-bottom: auto;
    }
  }

  .confirmation {
    grid-area: confirmation;
  }

  .status {
    grid-area: status;
  }

  .ticket {
    grid-area: ticket;
    @include lgMax {
      margin-left: 0;
      margin-right: auto;
    }
  }
}

.layoverTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  border-top: 1px solid $grey-2;
  border-bottom: 1px solid $grey-2;
  padding: 8px 0;
  margin: 20px 0;
}

.customLine {
  height: initial;
  align-items: flex-start;
  position: relative;
  width: 100%;

  & > div:last-of-type {
    max-width: 70%;
  }
}
