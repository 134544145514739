@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';

.searchContainer {
  margin-top: 20px;
  width: 100%;
  height: 40px;
}

.search {
  width: 100%;
  height: 36px;
  background-color: $clarity-white;
  border: 1px solid $grey-5;
  color: $grey-5;
  @include flex(row, nowrap, flex-start);
  & > * {
    margin-left: 12px;
  }
}

.emptyContainer {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $grey-6;
}

.spinner {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
