@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';

.menuContainer {
  width: 100%;
  @include flex(row, nowrap, flex-start, center);
}

.menu {
  height: 40px;
  @include flex(row, nowrap, flex-start);
  // border-bottom: 1px solid $grey-2;
  position: relative;
}

.menuItem {
  @include flex(row, nowrap, flex-start);
  font-weight: 500;
  color: $charcoal-grey;
  //color: #909090;
  cursor: pointer;
  margin-right: 12px;
  position: relative;

  &.disabledItem {
    color: #909090;
    cursor: default;
  }

  & > * {
    margin-right: 10px;
  }
}

.activeItem {
  color: $midnight-black;

  &::after {
    content: '';
    position: absolute;
    height: 40px;
    width: 100%;
    border-bottom: 2px solid $deep-green;
  }
}

.symbol {
  @include text(12px, 500, 16px);
  background-color: $midnight-black;
  color: $clarity-white;
  width: 20px;
  height: 20px;
  @include flex;
}

.disabledBox {
  background-color: #909090;
}

.activeBox {
  background-color: $deep-green;
}

.line {
  width: 100%;
  border-bottom: 1px solid $grey-2;
}
