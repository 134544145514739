@import 'styles/palette';

p {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  background: $ui-orange-lighten;
  padding: 11.32px 16px 11.32px 17.35px;
  justify-content: space-between;

  .info {
    display: flex;
    flex-wrap: wrap;
  }

  .source,
  .phone {
    margin-right: 25px;
  }

  .phone {
    display: flex;
    flex-grow: 1;

    .img {
      margin-right: 5px;
    }
  }
}
