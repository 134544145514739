@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';

.tabs {
  @include flex(row, nowrap, flex-start, center);
  @include text(14px, 500, 20px);
  width: 100%;
  min-height: 40px;
  background-color: $grey-1;
  padding: 4px;

  & > div {
    @include flex;
    height: 32px;
    flex: auto;
    cursor: pointer;
    color: $charcoal-grey;
  }

  .active {
    background-color: $clarity-white;
    color: $midnight-black;
  }

  .disabled {
    background: transparent;
    cursor: not-allowed;

    p {
      color: #909090 !important;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}

.count {
  @include flex;
  @include text(12px, 500, 16px);
  border-radius: 40%;
  background-color: grey;
  color: $clarity-white;
  margin-left: 6px;
  padding: 3px 6px;
}

.activeCount {
  background-color: $deep-green;
}
