@import 'styles/layout';
@import 'styles/typography';
@import 'styles/palette';

.badge {
  background: $grey-6;
  display: inline-block;
  color: $clarity-white;
  padding: 2px 6px;
  border-radius: 999px;
  &:hover {
    background-color: $deep-green;
  }
}
.tripItem {
  &:not(:last-child) {
    border-bottom: 2px solid $grey-1;
  }

  &.canceled {
    background: $grey-1;
  }

  .titleBlock {
    display: flex;
    gap: 6px;
    align-items: center;
    overflow: hidden;
  }

  .canceledBlock {
    background: $grey-6;

    padding: 2px 6px;
    color: $clarity-white;
  }
}
.currentTrip {
  background: $green-highlight;
  cursor: not-allowed;
}
.gridColumns {
  grid-template-columns: 16px repeat(4, calc(25% - 4px - 8px)) !important;
}
.gridColumnsSmall {
  grid-template-columns: 16px repeat(2, calc(50% - 16px)) !important;
}
